import { type SanityClickUpDynamicParameter } from '@data/sanity/queries/types/blocks'
import { type CustomFieldNameMap } from './services'

export interface ContactFormValues {
  email: string
  phone: string
  firstName: string
  lastName: string
  company: string
  message?: string
  acceptTerms?: boolean
}

export interface DemoFormValues {
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  company?: string
  acceptTerms?: boolean
  subscribeToNewsletter?: boolean
}

export interface RequestForPriceFormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  company?: string
  message?: string
}

/**
 * Gets contact form custom field mapping from dynamic parameters.
 */
export const getContactFormCustomFieldNameMap = (
  dynamicParameters?: SanityClickUpDynamicParameter[]
) => {
  const firstNameParameter = dynamicParameters?.find(
    (dynamicParameter) => dynamicParameter.source === 'contact-firstName'
  )
  const lastNameParameter = dynamicParameters?.find(
    (dynamicParameter) => dynamicParameter.source === 'contact-lastName'
  )
  const emailParameter = dynamicParameters?.find(
    (dynamicParameter) => dynamicParameter.source === 'contact-email'
  )
  const phoneParameter = dynamicParameters?.find(
    (dynamicParameter) => dynamicParameter.source === 'contact-phone'
  )
  const companyParameter = dynamicParameters?.find(
    (dynamicParameter) => dynamicParameter.source === 'contact-company'
  )

  const customFieldNameMap: CustomFieldNameMap = {
    firstName: firstNameParameter?.clickUpCustomFieldName,
    lastName: lastNameParameter?.clickUpCustomFieldName,
    email: emailParameter?.clickUpCustomFieldName,
    phone: phoneParameter?.clickUpCustomFieldName,
    company: companyParameter?.clickUpCustomFieldName,
  }

  return customFieldNameMap
}
