import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { recoverShopifyCustomerPassword } from '@lib/shopify/graphql/customers'
import { type SanityPasswordRecoveryFormStrings } from '@data/sanity/queries/types/blocks'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { type ErrorMessages, ParseError } from '@lib/request'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { type PasswordRecoveryFormValues } from '@lib/user/types'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field/with-form-register'
import ComplexPortableText from '@components/complex-portable-text'

interface PasswordRecoveryFormProps {
  className?: string
  passwordRecoveryFormStrings: SanityPasswordRecoveryFormStrings
}

const PasswordRecoveryForm = ({
  passwordRecoveryFormStrings,
  className,
}: PasswordRecoveryFormProps) => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<PasswordRecoveryFormValues>()

  const onSubmit = useCallback(
    async (values: PasswordRecoveryFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      setIsLoading(true)
      setIsError(false)

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        await recoverShopifyCustomerPassword(
          locale,
          shopifyStorefrontGraphQlClient,
          values.email
        )

        reset()
        setIsSuccess(true)
      } catch (error) {
        console.log(error)

        setIsError(true)

        if (error instanceof ParseError) {
          setErrorMessages(error.parseResults.fieldErrors)
        }
      }

      setIsLoading(false)
    },
    [locale, reset, shopifyStorefrontGraphQlClient]
  )

  const emailRegister = register('email', {
    required: passwordRecoveryFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const isDisabled = !!errors.email

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert success>
              <ComplexPortableText
                className="rc rc-alert rc-success"
                content={
                  passwordRecoveryFormStrings.passwordRecoverySuccessMessage
                }
              />
            </Alert>
          </motion.div>
        )}

        {!isSuccess && (
          <motion.div
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <InputField
              type="email"
              autoComplete="email"
              formRegister={emailRegister}
              errorMessage={errorMessages?.email ?? errors.email?.message}
              label={passwordRecoveryFormStrings.signupEmail}
              placeholder={passwordRecoveryFormStrings.signupEmailPlaceholder}
            />

            {isError && (
              <div key="error" className="mt-8">
                <Alert error>
                  <ComplexPortableText
                    className="rc rc-alert rc-error"
                    content={
                      passwordRecoveryFormStrings.passwordRecoveryErrorMessage
                    }
                  />
                </Alert>
              </div>
            )}

            <div className="flex justify-center mt-8">
              <Button
                type="submit"
                variant={ButtonVariant.FILLED}
                disabled={isLoading || isDisabled}
                className="min-w-[260px]"
              >
                {isLoading
                  ? strings.buttonSubmitting
                  : passwordRecoveryFormStrings.passwordRecoverySubmit}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default PasswordRecoveryForm
