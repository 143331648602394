import cx from 'classnames'
import { useMemo } from 'react'

import {
  type SanityResponsiveSpacingBreakpoint,
  type SanityGridModule,
} from '@data/sanity/queries/types/modules'

import { getGridSizeClass, getItemOrderClass } from '@lib/dom'
import { getResponsiveSpacingClasses } from '@lib/spacing'
import { backgroundColorClasses } from '@lib/color'

import GridBlock from './grid-block'

type GridProps = Pick<
  SanityGridModule,
  | 'size'
  | 'spacing'
  | 'background'
  | 'columns'
  | 'reverseSequence'
  | 'noColumnGaps'
  | 'noRowGaps'
>

const Grid = ({
  size,
  spacing,
  background,
  columns: rawColumns,
  reverseSequence,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = useMemo(
    () =>
      rawColumns?.map((column, index) => {
        if (!column.sizes) {
          return {
            ...column,
            classes: [],
          }
        }

        const columnClasses = column.sizes.map(
          ({ breakpoint, width, justify, align, start }) =>
            getGridSizeClass(breakpoint, width, justify, align, start)
        )
        const orderClasses = column.sizes.map(({ breakpoint, width }) =>
          getItemOrderClass(index, breakpoint, width, size, reverseSequence)
        )

        const spacingClasses = getResponsiveSpacingClasses(
          column.sizes
            .filter((columnSize) => !!columnSize.spacing)
            .map(
              (columnSize) =>
                ({
                  size: columnSize.breakpoint,
                  spacing: columnSize.spacing,
                } as SanityResponsiveSpacingBreakpoint)
            )
        )

        return {
          ...column,
          classes: [...columnClasses, ...orderClasses, ...spacingClasses],
        }
      }),
    [rawColumns, reverseSequence, size]
  )

  return (
    <div className={cx(background && backgroundColorClasses[background], 'w-full')}>
      <section
        className={cx(
          'relative',
          { container: !noColumnGaps },
          spacing ? getResponsiveSpacingClasses(spacing) : 'py-10 md:py-16'
        )}
      >
        <div
          className={cx(`grid grid-cols-${size} gap-6 lg:gap-8`, {
            '!gap-x-0': noColumnGaps,
            '!gap-y-0': noRowGaps,
          })}
        >
          {columns?.map((column) => (
            <div key={column._key} className={cx(column.classes)}>
              {column.blocks?.map((block) => (
                <GridBlock key={block._key} block={block} />
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default Grid
