import { type SanityCollectionCarouselBlock } from '@data/sanity/queries/types/blocks'

import ProductCard from '@blocks/shop/product-card'
import Carousel from '@components/carousel'

type CollectionCarouselProps = Pick<SanityCollectionCarouselBlock, 'collection'>

const CollectionCarousel = ({ collection }: CollectionCarouselProps) => {
  return (
    <Carousel
      items={collection.products.map((product, index) => (
        <div key={`${index}-${product.productID}`}>
          <ProductCard product={product} showPrice showQuickAdd />
        </div>
      ))}
      hasArrows
    />
  )
}

export default CollectionCarousel
